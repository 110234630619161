import React from "react";
import { graphql } from "gatsby";

const MarkdownPageTemplate = ({ data }) => {
  const { page } = data;
  // Renders the raw markdown summary; adjust as needed if you’d prefer HTML
  return (
    <pre style={{ whiteSpace: "pre-wrap", fontFamily: "inherit" }}>
      {page.markdownSummary
        ? page.markdownSummary
        : "No markdown summary provided."}
    </pre>
  );
};

export const query = graphql`
  query MarkdownPageQuery($id: String!) {
    page: datoCmsPage(id: { eq: $id }) {
      markdownSummary
    }
  }
`;

export default MarkdownPageTemplate;
